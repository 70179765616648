.apexcharts-tooltip {
    padding: 5px; /* Уменьшение внутреннего отступа */
    line-height: 1.2; /* Уменьшение межстрочного интервала */
    /* Добавьте другие стили по необходимости */
}

.apexcharts-xaxistooltip {
    padding: 2px 5px; /* Зменшення внутрішніх відступів */
    line-height: 1.2; /* Зменшення міжрядкового інтервалу */
    /* Додаткові стилі за потреби */
}