@charset "utf-8";
/* CSS Document */

/*@import "reset.css";*/

#reset-this-root {
    all: initial;
    /*aspect-ratio: 0.5;*/
    /*width: 100%;*/
}

#mainbanner {
    background-image: url("./cdzv_background_card.png");
    opacity: 0.95;
    /*width: 100%;*/
    border-radius: 15px;
    /*background-color: rgba(0, 0, 0, 0.88);*/
    /*width: 960px;*/
    /*height: 540px;*/
    font-family: Helvetica, Arial, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", "serif", sans-serif;
}

.inside {
    padding: 3%;
    color: #aeaeae;
}

.line1 {
    margin-top: 20px;
    font-size: 130%;
    text-align: right;
}

.line1n0 {
    color: #617af8;
}

.line1n1 {
    color: #aeaeae;
}

.line1n2 {
    color: #fff;
}

.line2 {
    margin-top: 0;
    font-size: 230%;
    text-align: right;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1%;
}

.line2n1 {
    color: #36a286;
}

.long {
    color: #36a286;
}

.short {
    color: #ff0012;
}

.both {
    color: #617af8;
}

.line2n3 {
    color: #aac1e0;
}

.line3 {
    font-size: 30%;
    color: #fff;
    text-align: center;
}

.line4 {
    font-size: 100%;
    text-align: right;
    margin-top: 5%;
}

.line3n2 {
    color: #f88ea4;
}

.line3n3 {
    color: #b9ffad;
}

.line5 {
    font-size: 600%;
    text-align: right;
    font-weight: 700;
    margin-top: -2%;
    letter-spacing: 10px;
    text-shadow: 6px 5px 2px #393f49;
}

.line5n1 {
    color: #34b988;
}

.line6 {
    font-size: 180%;
    text-align: right;
    font-weight: 600;
    margin-top: -2%;
    color: #d1d9da;
}

.line7 {
    font-size: 160%;
    text-align: left;
    font-weight: 600;
    margin-top: 4%;
}

.flex-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.first {
    flex-grow: 3;
    text-align: left;
}

.second {
    flex-grow: 7;
    text-align: right;
}

.loss0 {
    /*font-family: "Times New Roman", Times, serif;*/
    /*font-family: sans-serif;*/
    margin-top: -30px;;
    color: #e88894;
    flex-grow: 3;
    text-align: left;
    font-size: 70%;
}

.loss1 {
    font-family: "Times New Roman", Times, serif;
    color: #d1d9da;
    /*flex-grow: 3;*/
    text-align: left;
    font-size: 70%;
}

.loss2 {
    font-family: "Times New Roman", Times, serif;
    color: #d1d9da;
    /*flex-grow: 3;*/
    text-align: left;
    font-size: 70%;
}

.line8 {
    font-family: "Times New Roman", Times, serif;
    text-align: right;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
}