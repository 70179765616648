.Layout {
    border-radius: 0;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
}

.Sider {
    /*position: fixed;*/
    overflow: auto;
}

.Header {
    text-align: center;
    /*color: #ffffff;*/
    color: #000000;
    height: 64px;
    padding-inline: 48px;
    line-height: 64px;
    background-color: #ffffff;
    /*background-color: #282c34*/
}

.Content {
    text-align: center;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow: auto;
    line-height: 120px;
    color: #000000;
    background-color: #efefef;
}

.Footer {
    text-align: center;
    /*color: #efefef;*/
    /*background-color: #282c34;*/
}

.Footer a {
    color: #617af8;
}

.Footer a:hover {
    font-weight: 600;
    color: #030342;
}