.menu {
    font-family: Terminal, monospace;
    font-size: 0.9em;
    font-weight: 500;
    width: 220px;
}

/*.ant-tooltip-inner {*/
/*    color: #030342;*/
/*    background-color: #617af8;*/
/*    width: 200px;*/
/*}*/